import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How to be successful at interviews`}</h1>
    <div className="text-sm italic">
    Note: This is a Work In Progress article. In here, I will write about my experiences and lessons learned while interviewing for a tech job.
    <br />Last Updated: 26 August 2020
    </div>
    <p>{`The aim of this article is to gather every piece of advice that I have read/received on how to be successful at tech interviews. I will also share my experiences whilst interviewing for a developer position and share with you the mistakes that I have done, so you don't do them yourself.`}</p>
    <h2>{`Screening`}</h2>
    <p>{`To get an interview, first, you need to have a CV good enough to make the person reading your CV want to call you. `}</p>
    <p>{`The best piece of advice that I have received was from `}<a parentName="p" {...{
        "href": "https://www.twitter.com/_jacobtomlinson"
      }}>{`Jacob Tomlinson`}</a>{`. He said that I should structure my CV in the same way as the job requirements that I am applying.`}</p>
    <p>{`For example, if the company is looking for a Python developer that has experience with open source. You will put first your experience of contributing to Open Source Python Projects.`}</p>
    <p>{`Also, show results and not actions. For example, instead of saying:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Wrote code to integrate x with y`}</p>
    </blockquote>
    <p>{`You should write`}</p>
    <blockquote>
      <p parentName="blockquote">{`Wrote code to integrate with y that brought a speed performance of z %.`}</p>
    </blockquote>
    <h2>{`First interview`}</h2>
    <p>{`The first interview is usually a phone screening interview. On this stage, you should read EVERYTHING about the company. Study the website, read news about the company, do research, so when you are on the phone, you can mention things that the company does/did.`}</p>
    <p>{`Doing your research will also allow you to know what the company wants. On the interview, you can relate your experiences to the company needs.`}</p>
    <p>{`For example, if a company says that they are looking for someone that is not afraid of working with minimal information.  You can mention times where you had to code something without knowing how things work because the documentation was lacking.`}</p>
    <p>{`You should also sound excited about the opportunity and eager to work there.`}</p>
    <h2>{`Live code challenge interview`}</h2>
    <p>{`This step is probably the most stressful. My experience so far has been a pleasant one.  The interviewer was happy to help me through some issues.`}</p>
    <p>{`Usually this step, you have an interview with someone from the team that you will be working on, there will be a quick chat about the work and what is expected, of you, then a code challenge.`}</p>
    <p>{`The one piece of advice  I have for this stage is:`}</p>
    <blockquote>
      <p parentName="blockquote">{`Read the notes/problem properly and think it thought before jumping into the code!`}</p>
    </blockquote>
    <p>{`If you aren't great at coding challenges, then you could spend some time doing code challenges, since this is all about practice.`}</p>
    <h3>{`Tips`}</h3>
    <ul>
      <li parentName="ul">{`Read the notes/problem properly`}</li>
      <li parentName="ul">{`Think it through`}</li>
      <li parentName="ul">{`Ask for clarification`}</li>
      <li parentName="ul">{`Talk about assumptions and ask the interviewer if you are correct`}</li>
      <li parentName="ul">{`Talk about how you could solve the challenge`}</li>
      <li parentName="ul">{`Code the thing`}</li>
      <li parentName="ul">{`Talk about how to improve the code`}</li>
      <li parentName="ul">{`Talk about how to test the code`}</li>
      <li parentName="ul">{`Think about edge cases`}</li>
    </ul>
    <p>{`Depending on the role that you are applying for, you might be asked, about Big O notation. You might also have to do a code challenge about algorithms or where using a specific algorithm might be the right answer. Knowing the basic ones will be useful.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      